var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TogglePanel',{attrs:{"title":`${_vm.$tc('script', 2)} (${_vm.$tc('global', 1)})`,"icon":{
      before: 'fa fa-file-code-o',
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }}},[_c('div',{staticClass:"text-center"},[_c('ScriptsForm')],1)]),_c('TogglePanel',{attrs:{"title":`${_vm.$tc('event', 2)} (${_vm.$tc('local', 1)})`,"icon":{
      before: 'fa fa-flash',
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }}},[_c('div',[_c('ControlEventEditor',{staticStyle:{"margin":"10px -10px 0 -10px"},attrs:{"control":_vm.templateAsControl},on:{"delEvent":function($event){return _vm.onDelEvent($event)},"addEvent":function($event){return _vm.onAddEvent($event)}}})],1)]),_c('TogglePanel',{attrs:{"title":"dataset","persistent":"toggle_dashboard_dataset","icon":{
      before: 'fa fa-database',
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }}},[_c('NamedQueriesForm')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }